import request from './request'
import { QueryPageDto, QueryMonthDto, QueryDateSlotDto } from './dto'
interface GetDto extends QueryPageDto, QueryDateSlotDto{
}
export interface ContractRecordDto{
    id: number
    companyId: number
    companyName: string
    serveCompanyName: string
    projectId: number
    userId: number
    userName: string
    idCardNo: string
    flowId: string
    flowName: string // 合同名称
    createOn: string // 发起时间
    deadline: string // 截止时间
    signingTime: string
    labourCost: string // 工价
    flowStatus: string // 签署状态,INIT=已创建,PART=签署中,REJECT=拒签,CANCEL=撤回,DEADLINE=流签,ALL=签署完成
    isSuccess: string // 是否签署 1:已签署,0:未签署
    flowMessage: string // 签署信息
}
// 获取合同列表
export function getContractRecord (params: GetDto): Promise<{total: number, list: ContractRecordDto[]}> {
    return request.get('/rest/elesign/ele-sign-contract-record/list', {
        params
    })
}

// 获取合同模板列表
export function getContractTempletes (params: GetDto): Promise<{total: number, list: ContractRecordDto[]}> {
    return request.get('/rest/elesign/ele-sign-contract-template/list', {
        params
    })
}

export function openContract (): Promise<{consoleUrl: string}> {
    return request.get('/api/v1/ele-sign/company-open')
}

// 修改合同年限
export function setContractYear (data: {id: number, effectiveTime: number, unit:number}): Promise<void> {
    return request.put('/rest/elesign/ele-sign-contract-template', data)
}

// 查看合同详情
export function openContractDetail (flowId: string): Promise<{consoleUrl: string}> {
    return request.get(`/rest/elesign/ele-sign-contract-record/view/${flowId}/contract`)
}
